import {
  faFacebook,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Footer.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      {/*   <form ref={form} onSubmit={sendEmail} className="contactme">
        <div>
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
          />
        </div>

        <div>
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            required
          />
        </div>

        <div>
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            name="message"
            required
          />
        </div>

        <button type="submit" className="btn btn_contactme_submit">
          Submit
        </button>
      </form> */}
      <ToastContainer position="top-center" />
      <div>
        <a
          href="https://www.instagram.com/gcheung_g"
          style={{ color: "black" }}
        >
          {" "}
          <FontAwesomeIcon icon={faInstagram} className="socials" />{" "}
        </a>
        <a
          href="https://github.com/GeeCheung/music_theory"
          style={{ color: "black" }}
        >
          {" "}
          <FontAwesomeIcon icon={faGithub} className="socials" />
        </a>
        <a
          href="https://www.facebook.com/gee.cheung.503"
          style={{ color: "black" }}
        >
          {" "}
          <FontAwesomeIcon icon={faFacebook} className="socials" />
        </a>
      </div>
      <footer>{`Copyright © GeeMusic_Theory ${year}`}</footer>
    </div>
  );
};

export default Footer;
